import {httpProvider} from "@Newfiling/Services";

export const all = (data) => {
    return httpProvider.post('/ejobsearch/searchforjob', data);
};

export const find = (id) => {
    return httpProvider.get(`/ejobsearch/${id}`);
};

export const destroy = (id) => {
    return httpProvider.delete(`/eJobsearchEditandel/${id}`);
};

export const update = (id,data) => {
    return httpProvider.put(`/eJobsearchEditandel/${id}`, data);
};

export const updateStatus = (data) => {
    return httpProvider.post(`/Immediateapprovalofjobsearch`, data);
};


